
import common from '@/mixin/common.js';
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'r-technology',
	mixins: [common],
	data() {
		return {
			// productBgImage: require('@/assets/technology/bg.jpg'),
			// bgImage1: require('@/assets/technology/bg1.jpg'),
			// bgImage2: require('@/assets/technology/bg2.jpg'),
			// contentBgImage: require('@/assets/technology/content_bg.png'),
			bgImage1: require('@/assets/technology/1.jpg'),
			bgImage2: require('@/assets/technology/2.jpg'),
			contentBgImage: require('@/assets/technology/3.jpg'),
			productInfo: [
				require('@/assets/technology/product_1.jpg'), 
				require('@/assets/technology/product_2.jpg')
			],
			playerOptions: {
				autoplay: false, //如果true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: true, // 导致视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				// language: 'zh-CN',
				language: 'en',
				// aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				poster: '', //你的封面地址
				notSupportedMessage: '此视频暂无法播放，请稍后再试',
			},
			videoInfo: [
				1,
				2,
				3,
			]
		}
	},
	mounted() {
		// this.scrollToTop();
		if (this.videoUrl) {
			this.resetPath();
		} else {
			this.getVideoPath();
		}
	},
	components: {
		videoPlayer
	},
	computed: {
		...mapState('technology', ['videoUrl'])
	},
	methods: {
		...mapActions('technology', ['getVideoPath']),
		resetPath() {
			this.playerOptions = {
				autoplay: false, //如果true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: true, // 导致视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				// language: 'zh-CN',
				language: 'en',
				// aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [{
					type: "video/mp4",
					src: this.videoUrl
				}],
				poster: require('@/assets/technology/poster.jpg'), // 你的封面地址
				notSupportedMessage: '此视频暂无法播放，请稍后再试',
			}
		}
	},
	watch: {
		videoUrl: function() {
			this.resetPath();
		}
	}
}